import React, { useState, useRef, useEffect } from 'react';
import time from './time.png';
import { Alert, Container, Row, Col, Form, Button } from "react-bootstrap";
import Countdown from "react-countdown";
import emailjs from '@emailjs/browser';
import { usePapaParse } from 'react-papaparse';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  var now = new Date();
  const [currentTime, setCurrentTime] = useState(now.getHours() + ':' + now.getMinutes());
  // const [currentUserTime, setCurrentUserTime] = useState(725);
  const [currentUserTime, setCurrentUserTime] = useState(now.getHours()*60+now.getMinutes());
  const [zacetek, setZacetek] = useState(690);
  const [konec, setKonec] = useState(900);
  const [send, setSend] = useState(false);
  const [data, setData] = useState(["AKTIVNA", "Trenutno ni obvestil."]);
  const [aktivno, setAktivno] = useState([]);
  const [loading, setLoading] = useState(true);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_kzn1tjt', 'template_jdh1jza', form.current, 'user_CpsNB7hk9cVMGyekwjZzW')
      .then((result) => {
          console.log(result.text);
          setSend(true);
      }, (error) => {
          console.log(error.text);
      });
  };

  const { readRemoteFile  } = usePapaParse();

  useEffect(() => {
    readRemoteFile("https://docs.google.com/spreadsheets/d/e/2PACX-1vQpBvY4huoS4Q4YJPx9-8jUuR8MJMTO0Qsufx7I7RgwOYUIhj9AGSXGGqk3EntFMbaXCmcD3E7UZ8cC/pub?output=csv", {
      complete: (results) => {
        setData(results.data[1]);
        setAktivno(results.data[1][2]);
      },
    });
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, [])

  if (loading) {
    return "Nalagam ..."
  }

  return (
    <div className="App">
      <img src={time} className="App-logo" alt="logo" />
      <h1 style={{marginBottom: "0", paddingBottom: "1rem"}}>HITRA DOSTAVA <br />12:00 - 15:00</h1>
      <h2 style={{marginBottom: "2rem"}}>Običajni delovni čas:<br /> 15:00 - 19:00</h2>
      <Alert variant="info">
        <Alert.Heading>Obvestila:</Alert.Heading>
        <p>{data[1]}</p>
      </Alert>
      <Container>
        { (data[0] === "AKTIVNO" && (now.getDay() !== 0) && ( currentUserTime >= zacetek && currentUserTime <= konec ) ) ?
          <Alert variant={"success"}>
            <Alert.Heading>Hitra dostava: AKTIVNA</Alert.Heading>
            <div>
              <div>
                Dostavljamo še: <br /><Countdown className="odstevalnik" date={Date.now() + (60000*(900-currentUserTime))} />
              </div>
              <div>Trenutno je ura {now.getHours() < 10 && "0"} {`${now.getHours()}:`}{now.getMinutes() < 10 && "0"}{`${now.getMinutes()}`}</div>
            </div>
          </Alert>
          :
          <Alert variant="danger">
            <Alert.Heading>Hitra dostava: NEAKTIVNA</Alert.Heading>
            <p>
              Hitre dostave danes ni več. Ura je {now.getHours() < 10 && "0"} {`${now.getHours()}:`}{now.getMinutes() < 10 && "0"}{`${now.getMinutes()}`}.
            </p>
          </Alert>
        }
      </Container>
      { (data[0] === "AKTIVNO" && (now.getDay() !== 0) && ( currentUserTime >= zacetek && currentUserTime <= konec ) ) &&
        <Container>
          <Form ref={form} onSubmit={sendEmail}>
            <Row>
              <Form.Label column lg={2}>Telefonska številka:</Form.Label>
              <Col>
                <Form.Control name="phone" type="text" placeholder="primer: 031102902"/>
              </Col>
            </Row>
            {!send ?
              <Button variant="outline-primary" type="submit">
                Naroči
              </Button>
              :
              <Button variant="outline-primary" disabled>Naročilo oddano</Button>
            }
          </Form>
        </Container>
      }

    <Alert variant="warning">
      <Alert.Heading>Naš delovni čas</Alert.Heading>
      <p>Hitra dostava deluje od ponedeljka do sobote od 12:00 do 15:00.</p>
      <p>Običajna dostava (preko telefona) deluje od 15:00 do 19:00 (ponedeljek - sobota).</p>
    </Alert>

    <p>
      Storitev hitre dostave je namenjena le obstoječim uporabnikom. Dodatne informacije: info@hitradostava.si.
    </p>

    </div>
  );
}

export default App;
